import './styles.scss';

import { FC, HTMLAttributes } from 'react';
import { CloseIcon, TickIcon } from '@flotilla/component-library';

export type RAGStatusType = "red" | "amber" | "green";

interface RAGStatusProps extends HTMLAttributes<HTMLElement> {
  status?: RAGStatusType;
};

const RAGStatus: FC<RAGStatusProps> = ({status = "red", className = '', title, ...props}) => {
  const getIcon = () => {
    switch (status) {
      case 'amber':
        return  <TickIcon variant='dark' />;
      case 'green':
        return  <TickIcon variant='light' />;
      case 'red':
      default:
        return  <CloseIcon variant='light' />;
    }
  }
  return (
    <section {...props} className={`rag-status rag-status--${status} ${className}`} title={`${status[0].toUpperCase}${status.slice(1)} Status`}>
      { getIcon() }
    </section>
  )
};

export default RAGStatus;
