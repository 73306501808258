import './styles.scss';

import React, { useState } from 'react';

import {
  ProgressStats,
  ReductionChart,
  ImpactAreas,
  ActionsSummary,
  ActionsTable,
  CarbonFootprint
} from '../../../../components/DashboardWidgets';
import Header from '../../../../components/HeaderV2';
import { Button } from '@flotilla/component-library';
import EditTargetsModal from '../../../../components/EditTargetsModal';
import { useUserAccess } from '../../../../context';

interface DashboardProps {
  className?: string;
};

const Dashboard: React.FC<DashboardProps> = ({
  className = "",
}) => {
  const { userAccess } = useUserAccess();
  const { EDIT } = userAccess;
  const [editingTargets, setEditingTargets] = useState(false);
  const [key, setKey] = useState(0);
  const [locked, setLocked] = useState(true);

  const handleTargetsClose = (updated = false) => {
    setEditingTargets(false);

    if(updated) {
      setKey(key + 1);
    }
  }

  return (
    <section id="net-zero-dashboard-page" className={className}>
      <Header>
        Net Zero Dashboard
      </Header>
      <section id="edit-targets-btn">
        { !locked && EDIT && (
          <Button 
            variant='ghost'
            onClick={() => { setEditingTargets(true)}}>
            Edit targets
          </Button>
        )}
      </section>
      {editingTargets && (
        <EditTargetsModal 
          onClose={handleTargetsClose}
          onSubmit={() => handleTargetsClose(true)}
        />
      )}
      <main>
        <ProgressStats onLockedStatusUpdate={setLocked} />
        <ReductionChart key={key}/>
        <section id="main-information">
          <section id="emission-impact-areas">
            <ImpactAreas />
            <CarbonFootprint />
            <ActionsSummary />
          </section>
          <section id="actions-container">
            <ActionsTable />
          </section>
        </section>
      </main>
    </section>
  );
}

export default Dashboard;
