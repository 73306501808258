import './styles.scss';

import React from 'react';
import { Tag, TagProps } from '@flotilla/component-library';

import { ReactComponent as TrophyIcon } from "../../assets/icons/Trophy.svg";

interface QuickWinTagProps extends Omit<TagProps, "children"> {
  showLabel?: boolean;
};

const QuickWinTag: React.FC<QuickWinTagProps> = ({className, showLabel, ...props}) => (
  <Tag
    {...props}
    className={`tag quick-win-tag ${showLabel ? "quick-win-tag--show-label" : ""} ${className}`}
    title="Quick Win"
    icon={<TrophyIcon id="quick-win" />}
    children={showLabel ? "Quick Win" : ''}
  />
);

export default QuickWinTag;
