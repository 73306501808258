import { ScopeCategory } from "./Action";
import { Location, Locations, mapLocations } from "./Location";
import { Measure } from "./Measure";
import { QuestionGroups } from "./Question";
import { Supplier } from "./Supplier";
import { User } from "./User";

export type DataPeriodDatesItem = {
  startDate: string;
  endDate: string;
  name: string;
}

export type DataPeriodDates = DataPeriodDatesItem[];

export type DataPeriod = {
  id: string;
  companyId: number;
  name: string;
  startDate: string;
  endDate: string;
  status: DataPeriodStatus;
  isSubmitted?: boolean;
  turnover: number | undefined;
  costOfSales: number | undefined;
  balanceSheet: number | undefined;
  fte: number | undefined;
  additionalInfo: string;
  tco2e: number;
  compliances?: Compliances;
  createdAt: string;
  updatedAt: string;
  score?: number;
  audits?: AuditDataPeriod[];
  signature?: string;
};

export type Audit = {
  id: number;
  date: string;
  action: string;
  targetId: number;
  userId?: number;
  change: AuditChange;
  user?: User;
}

export type AuditDataPeriod = {
  measure?: string;
  location?: string;
} & Audit;

export type AuditChange = {
  columnName: string;
  originalValue: string | number | null;
  newValue: string | number | null;
}

export type Compliance = {
  id: number;
  name: string;
  compliant?: boolean;
}

export type Compliances = Compliance[];

export type SupplierMeasureDataItem = {
  supplierActivityId: number;
  measureValue: number;
}

export type SupplierMeasureData = SupplierMeasureDataItem[];

export type SupplierActivity = {
  id: number;
  activityId: number;
  supplierId: number;
  supplier: Supplier;
  supplierActivityEmissionData: ActivityData;
}

export type SupplierActivities = SupplierActivity[];

export type ActivityDataItem = {
  id: number;
  factorValueId: number;
  measureId: number;
  dataPeriodTabId: number;
  focusGroupId: number;
  activityId: number;
  minValue: number;
  maxValue?: number;
  value?: number;
  lastSubmittedValue?: number;
  measure: Measure;
  supplierMeasureData: SupplierMeasureData;
  locationId?: number;
  required: boolean;
  isEstimation: boolean;
  wasEstimation: boolean;
  submittedAt?: string;
}

export type ActivityData = ActivityDataItem[];

export type ActivityNote = {
  activityId: number;
  notes?: string;
  file?: File;
  documentName?: string;
  documentationUrl?: string;
  locationId?: number;
  focusGroupId?: number;
  dataPeriodTabId?: number;
  dataPeriodId?: number;
  isEstimation?: boolean;
}

export type ActivityNotes = ActivityNote[];

export type EmissionDataItem = {
  id: number;
  dataPeriodId: number;
  activityId: number;
  measureId: number;
  dateFrom: string;
  dateTo: string;
  // minValue: number;
  // maxValue?: number;
  value: number;
  tco2e: number;
  measure: Measure;
  isEstimation: boolean;
  wasEstimation: boolean;
  createdAt: string;
  updatedAt: string;
  submittedAt?: string;
  lastSubmittedValue?: number;
}

export type EmissionData = EmissionDataItem[];

export type FactorValue = {
  id: number;
  activityId: number;
  measureId: number;
  minValue: number;
  maxValue?: number;
  dateFrom: string;
  dateTo: string;
  value: number;
  measure: Measure;
}

export type FactorValues = FactorValue[];

export type Activity = {
  id: number,
  name: string;
  focusGroupId: number;
  focusGroup: FocusGroup;
  factorValues: FactorValues;
  emissionData: EmissionData;
  data: ActivityData;
  supplierActivities: SupplierActivities;
  mandatory: boolean;
  compliances: Compliances;
  completed?: boolean;
  tooltip?: string;
  helpText?: string;
  shortName: string;
  searchTerms?: string[];
  activityNote: ActivityNote;
  scopeCategory: ScopeCategory;
  estimate?: boolean;
  //TODO : Remove this
  activityNotes?: ActivityNotes;
  mappingTooltip?: string;
}

export type Activities = Activity[];

export type FocusGroup = {
  id: number;
  name: string;
  shortName?: string;
  impactAreaId: number;
  activities: Activities;
  tabId: number;
  accordionId: number;
  completed?: boolean;
  tooltip?: string;
}

export type FocusGroups = FocusGroup[];

export type DataPeriodTab = {
  id: number;
  name: string;
  focusGroups: FocusGroups;
  locations?: Locations;
  completed?: boolean;
  order: number;
}

export type DataPeriodTabs = DataPeriodTab[];

export type DataPeriods = DataPeriod[];

export const dataPeriodStatus = [
  "",
  "Not Started",
  "Pending",
  "Submitted"
];
export type DataPeriodStatus = typeof dataPeriodStatus[number];

export type DataPeriodUpdateData = {
  data: ActivityData;
  tabs: DataPeriodTabs;
}

export type LocationQuestion = {
  location: Location;
  questions: QuestionGroups;
}

export type LocationQuestions = LocationQuestion[];

export type DataPeriodQuestions = {
  questions: QuestionGroups;
  locationQuestions: LocationQuestions;
  locations: Locations;
}

export const getDataPeriodStatusId = (value: string = "Not Started"): number => {
  return dataPeriodStatus.findIndex((status) => status === value);
}

export const mapDataPeriods = (data: Partial<DataPeriod>[]): DataPeriods => {
  return data.map((item: Partial<DataPeriod> | any) => ({
    ...item,
    tco2e: item.tco2E,
    status: dataPeriodStatus[item.status as unknown as number],
    isSubmitted: item.status === 3,
  })) as DataPeriods;
}

export const mapDataPeriodTabs = (data: Partial<DataPeriodTab>[], dataPeriodId?: number): DataPeriodTabs => {
  return data.map((item: Partial<DataPeriodTab> | any) => {
    const focusGroups = mapFocusGroups(item.focusGroups, item.id, dataPeriodId).sort((a, b) => String(a?.name.toLowerCase()) > String(b?.name.toLowerCase()) ? 1 : -1);
    const locations = mapLocations(item.locations, item.id, dataPeriodId).sort((a, b) => String(a?.name.toLowerCase()) > String(b?.name.toLowerCase()) ? 1 : -1);
    return {
      ...item,
      focusGroups,
      locations,
      completed: locations.length > 0 ? locations.every((item) => item.completed) : focusGroups.every((item) => item.completed)
    }
  }) as DataPeriodTabs;
}

export const mapFocusGroups = (data: Partial<FocusGroup>[], dataPeriodTabId: number, dataPeriodId?: number, locationId?: number): FocusGroups => {
  return data.map((item: Partial<FocusGroup> | any) => {
    const activities = mapActivities(item.activities, dataPeriodTabId, item, dataPeriodId, locationId).sort((a, b) => String(a?.name.toLowerCase()) > String(b?.name.toLowerCase()) ? 1 : -1);

    return {
      ...item,
      accordionId: Number(`${locationId || ''}${item.id}`),
      mandatory: item.mandatory || false,
      activities,
      completed: activities.every((item) => item.completed)
    }
  }) as FocusGroups;
}

export const mapActivities = (data: Partial<Activity>[], dataPeriodTabId: number, focusGroup: FocusGroup, dataPeriodId?: number, locationId?: number): Activities => {
  return data.map((item: Partial<Activity> | any) => {
    const activityData = (item?.data as ActivityData || mapActivityData(item.factorValues, item.activityEmissionData, dataPeriodTabId, focusGroup.id, item.mandatory, locationId))
      .sort((a, b) =>  b.measure.name === '£' ? 1 : a.measure.name === '£' ? -1 : a.measure.rank - b.measure.rank);
    return {
      ...item,
      data: activityData,
      emissionData: mapEmissionData(item.activityEmissionData),
      completed: activityData.some((data) => data.value !== undefined),
      focusGroup,
      activityNote: {
        activityId: item.id,
        dataPeriodTabId,
        focusGroupId: focusGroup.id,
        locationId,
        file: undefined,
        notes: '',
        dataPeriodId: dataPeriodId,
        ...item.activityNote
      }
    };
  }) as Activities;
}

export const mapActivityData = (factorValues: Partial<FactorValue>[], emissionData: Partial<EmissionDataItem>[], dataPeriodTabId: number, focusGroupId: number, mandatory: boolean = false, locationId?: number): ActivityData => {
  return factorValues.map((item: Partial<FactorValue> | any) => {
    const emissionDataItem = emissionData.find((emissionDataItem) => emissionDataItem.measureId === item.measureId);
    return ({
      id: emissionDataItem?.id,
      factorValueId: item.id,
      measureId: item.measureId,
      dataPeriodTabId,
      focusGroupId,
      activityId: item.activityId,
      value: emissionDataItem?.value,
      lastSubmittedValue: emissionDataItem?.lastSubmittedValue,
      measure: item.measure,
      locationId,
      required: emissionDataItem?.value ? mandatory : false,
      minValue: item.minValue,
      maxValue: item.maxValue,
      wasEstimation: emissionDataItem?.isEstimation ?? false,
      isEstimation: emissionDataItem?.isEstimation ?? false,
      submittedAt: emissionDataItem?.submittedAt
    });
  }) as ActivityData;
}

export const mapEmissionData = (data: Partial<EmissionDataItem>[]): EmissionData => {
  return data.map((item: Partial<EmissionDataItem> | any) => ({
    ...item,
    tco2e: item.tco2E
  })) as EmissionData;
}

export const mapDataPeriodDates = (data: Partial<DataPeriodDatesItem>[]): DataPeriodDates => {
  return data.map((item: Partial<DataPeriodDatesItem> | any) => ({
    ...item,
    name: `${new Date(item.startDate).toLocaleDateString()} - ${new Date(item.endDate).toLocaleDateString()}`,
  })) as DataPeriodDates;
}

export const getFakeTabData = () => ({
  "payload": [
    {
      "id": 4,
      "name": "Company cars",
      "impactAreaId": 1,
      "activities": [
        {
          "id": 1,
          "name": "Diesel",
          "focusGroupId": 4,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 2,
              "activityId": 1,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 32,
              "activityId": 1,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 2,
          "name": "Electric",
          "focusGroupId": 4,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 4,
              "activityId": 2,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 34,
              "activityId": 2,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 3,
          "name": "Hybrid",
          "focusGroupId": 4,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 6,
              "activityId": 3,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.04052388,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 36,
              "activityId": 3,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.04052388,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 4,
          "name": "Petrol",
          "focusGroupId": 4,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 8,
              "activityId": 4,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 38,
              "activityId": 4,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 5,
          "name": "Unknown",
          "focusGroupId": 4,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 10,
              "activityId": 5,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 40,
              "activityId": 5,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        }
      ]
    },
    {
      "id": 5,
      "name": "Company vans",
      "impactAreaId": 1,
      "activities": [
        {
          "id": 6,
          "name": "Diesel",
          "focusGroupId": 5,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 12,
              "activityId": 6,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 42,
              "activityId": 6,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 7,
          "name": "Electric",
          "focusGroupId": 5,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 14,
              "activityId": 7,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.001570993,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 44,
              "activityId": 7,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.001570993,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 8,
          "name": "Hybrid",
          "focusGroupId": 5,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 16,
              "activityId": 8,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 46,
              "activityId": 8,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 9,
          "name": "Petrol",
          "focusGroupId": 5,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 18,
              "activityId": 9,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 48,
              "activityId": 9,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 10,
          "name": "Unknown",
          "focusGroupId": 5,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 20,
              "activityId": 10,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 50,
              "activityId": 10,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        }
      ]
    },
    {
      "id": 6,
      "name": "Company HGV",
      "impactAreaId": 1,
      "activities": [
        {
          "id": 11,
          "name": "Diesel",
          "focusGroupId": 6,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 22,
              "activityId": 11,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 52,
              "activityId": 11,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 12,
          "name": "Electric",
          "focusGroupId": 6,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 24,
              "activityId": 12,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 54,
              "activityId": 12,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 13,
          "name": "Hybrid",
          "focusGroupId": 6,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 26,
              "activityId": 13,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 56,
              "activityId": 13,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 14,
          "name": "Petrol",
          "focusGroupId": 6,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 28,
              "activityId": 14,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 58,
              "activityId": 14,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        },
        {
          "id": 15,
          "name": "Unknown",
          "focusGroupId": 6,
          "scopeId": 1,
          "factorValues": [
            {
              "id": 30,
              "activityId": 15,
              "measureId": 1,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 1,
                "name": "£"
              }
            },
            {
              "id": 60,
              "activityId": 15,
              "measureId": 2,
              "dateFrom": "2024-01-01",
              "dateTo": "2024-12-31",
              "value": 0.0109524,
              "measure": {
                "id": 2,
                "name": "BusinessMiles"
              }
            }
          ],
          "emissionData": []
        }
      ]
    }
  ],
  "errorMessages": [],
  "exceptions": [],
  "success": true
});