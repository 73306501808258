import './styles.scss';

import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { CheckboxButtonV2 as CheckboxButton, Label, NumberInput, Select, Switch, TextArea, TextInput, Datalist, DatalistMulti, Options, Option } from '@flotilla/component-library';

import { Answer, Question as QuestionType, Questions as QuestionsType, Submissions } from '../../types/Question';
import ActivityTooltip from '../../pages/Company/Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';

interface QuestionProps {
  className?: string;
  question: QuestionType;
  onChange?: (questionId: number, submissions: Submissions | undefined) => void;
  disabled?: boolean;
};

const Question: React.FC<QuestionProps> = ({
  className = "",
  question,
  onChange = () => ({}),
  disabled = false
}) => {
  const {
    questionType
  } = question;
  const [submissions, setSubmissions] = useState<Submissions>();
  const [linkedQuestions, setLinkedQuestions] = useState<QuestionsType>();

  useEffect(() => {
    if(question.questionType === 'Toggle' && submissions && submissions?.length === 0) {
      var noAnswer = question.questionAnswers.findIndex(a => a.questionAnswerText.toLowerCase() === "no");
      setSubmission(question.questionAnswers[noAnswer === -1 ? 0 : noAnswer]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, submissions])

  const isSwitchYes = () => {
    const switchYes = question.questionAnswers.find((item) => item.questionAnswerText.toLowerCase() === "yes");
    return (switchYes?.questionAnswerSubmissions?.length || 0) > 0;
  }

  const handleChangeSubmissions = (updatedSubmissions: Submissions) => {
    setSubmissions(updatedSubmissions);
    onChange(question.id, updatedSubmissions);
  }

  const handleCheckboxChange = (answer: Answer) => {
    return (checked: boolean) => {
      if (checked) {
        if (question.questionType === "MultiCheckbox") {
          handleChangeSubmissions([
            ...(submissions || []),
            {
              id: answer.id,
              questionAnswerId: answer.id
            }
          ]);
        } else {
          handleChangeSubmissions([
            {
              id: answer.id,
              questionAnswerId: answer.id
            }
          ]);
        }
      } else if (!checked) {
        if (question.questionType === "MultiCheckbox") {
          handleChangeSubmissions(submissions?.filter((item) => item.questionAnswerId !== answer.id) || []);
        } else {
          handleChangeSubmissions([]);
        }
      }
    }
  }

  const handleSwitchChange = (checked?: boolean) => {
    setSubmission(question.questionAnswers[checked ? 0 : 1]);
  }

  const handleNumberChange = (answer: Answer) => {
    return (value: number | undefined) => {
      if (Number(submissions?.[0]?.freeTextAnswer) !== value && !(isNaN(Number(submissions?.[0]?.freeTextAnswer)) && isNaN(Number(value)))) {
        setSubmission(answer, (value !== undefined && !isNaN(value)) ? String(value || 0) : "");
      }
    }
  }

  const handleTextChange = (answer: Answer): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      setSubmission(answer, event.currentTarget.value);
    }
  }

  const handleTextAreaChange = (answer: Answer): ChangeEventHandler<HTMLTextAreaElement> => {
    return (event) => {
      setSubmission(answer, event.currentTarget.value);
    }
  }

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const answerId = Number(event.currentTarget.value);
    const answer = question.questionAnswers.find(item => item.id === answerId);
    answer && setSubmission(answer);
  }

  const handleDatalistMultiChange = (options: Options = []) => {
    const submissions: Submissions = options.map((item) => ({
        id: Number(item.id),
        questionAnswerId: Number(item.id),
        ...item
    }));
    handleChangeSubmissions(submissions);
  }

  const setSubmission = (answer: Answer, freeTextAnswer?: string) => {
    if (answer.linkedQuestion) {
      setLinkedQuestions([...(linkedQuestions || []), answer.linkedQuestion]);
    }

    handleChangeSubmissions([{
      id: answer.id,
      questionAnswerId: answer.id,
      freeTextAnswer
    }]);
  }

  useEffect(() => {
    if (["Checkbox", "MultiCheckbox", "Toggle"].includes(questionType)) {
      const updatedLinkedQuestions: QuestionsType = [];
      const submissionIds = submissions?.map((item) => item.questionAnswerId) || [];
      
      question.questionAnswers?.forEach((answer) => {
        if (answer.linkedQuestion && submissionIds.includes(answer.id) && !updatedLinkedQuestions?.find((item) => item.id === answer.linkedQuestionId)) {
          updatedLinkedQuestions.push(answer.linkedQuestion);
        }
      });
      
      setLinkedQuestions(updatedLinkedQuestions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, submissions, questionType]);

  useEffect(() => {
    const updatedSubmissions: Submissions = [];
    question.questionAnswers.forEach((item) => {
      item.questionAnswerSubmissions?.[0] && updatedSubmissions.push(item.questionAnswerSubmissions[0]);
    });
    setSubmissions(updatedSubmissions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const getDataListValues = (): Options => {
    const options: Options = submissions?.map((item) => {
      const label = question.questionAnswers.find((answerItem) => answerItem.id === item.questionAnswerId);
      return {
        value: String(item.questionAnswerId),
        label: label?.questionAnswerText,
        ...item
      } as Option;
    }) || [];

    return options;
  }

  const getQuestion = () => {
    if (['Checkbox', 'MultiCheckbox'].includes(question.questionType) &&  question.questionAnswers.length > 10) {
      if (question.questionType === 'MultiCheckbox') {
        return (
          <DatalistMulti
            id={`location-question-${question.id}`}
            className={`question question--datalistmulti ${className}`}
            label={`${question.questionText}${question.mandatory ? '*' : ''}`}
            options={question.questionAnswers.map((item) => ({ value: String(item.id), label: item.questionAnswerText, ...item })) || []}
            values={getDataListValues()}
            onChange={handleDatalistMultiChange}
          />
        )
      }
    
      return (
        <Datalist
          id={`location-question-${question.id}`}
          className={`question question--datalist ${className}`}
          label={`${question.questionText}${question.mandatory ? '*' : ''}`}
          options={question.questionAnswers.map((item) => ({ value: String(item.id), label: item.questionAnswerText, ...item })) || []}
          value={getDataListValues()[0]}
        />
      )
    }
    switch (question.questionType) {
      case 'Checkbox':
      case 'MultiCheckbox':
        return (
          <section
            id={`location-question-${question.id}`}
            className={`question question--checkbox ${className}`}
          >
            <Label subtitle={question.questionType === "MultiCheckbox" ? "Select all that apply" : undefined}>
              {question.questionText}{question.mandatory ? '*' : ''}
              <ActivityTooltip
                tooltip={question.tooltip}
                helpText={question.helpText} />
            </Label>
            <article className="checkbox-container">
              {question.questionAnswers?.map((answer) => (
                <>
                  <CheckboxButton
                    key={answer.id}
                    className="answer-checkbox"
                    label={answer.questionAnswerText}
                    onToggle={handleCheckboxChange(answer)}
                    checked={submissions?.find((item) => item.questionAnswerId === answer.id) ? true : false}
                    disabled={disabled}
                  />
                </>
              ))}
            </article>
          </section>
        );
      case 'Toggle':
        return (
          <section
            id={`location-question-${question.id}`}
            className={`question question--toggle ${className}`}
          >
            <label>
              {question.questionText}{question.mandatory ? '*' : ''}
              <ActivityTooltip
                tooltip={question.tooltip}
                helpText={question.helpText} />
            </label>
            <Switch
              onToggle={handleSwitchChange}
              checked={isSwitchYes()}
              small
              disabled={disabled}
            />
          </section>
        );
      case 'Select':
        return (
          <Select
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleSelectChange}
            value={submissions?.[0]?.id || undefined}
            disabled={disabled}
            required={question.mandatory}
          >
            {question.questionAnswers?.map((answer) => (
              <option
                key={answer.id}
                value={answer.id}
              >
                {answer.questionAnswerText}
              </option>
            ))}
          </Select>
        );
      case 'IntegerInput':
      case 'DecimalInput':
        return (
          <NumberInput
            key={question.id}
            id={`location-question-${question.id}`}
            className={`question question--number-input ${className}`}
            label={question.questionText}
            onChange={handleNumberChange(question.questionAnswers[0])}
            value={Number(submissions?.[0]?.freeTextAnswer) || undefined}
            decimal={question.questionType === "DecimalInput"}
            min={0}
            disabled={disabled}
            required={question.mandatory}
            financial
            tooltip={<ActivityTooltip
              tooltip={question.tooltip}
              helpText={question.helpText} />}
          />
        );
      case 'TextArea':
        return (
          <TextArea
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleTextAreaChange(question.questionAnswers[0])}
            value={submissions?.[0]?.freeTextAnswer || undefined}
            disabled={disabled}
            required={question.mandatory}
          />
        );
      case 'TextInput':
      default:
        return (
          <TextInput
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleTextChange(question.questionAnswers[0])}
            value={submissions?.[0]?.freeTextAnswer || undefined}
            disabled={disabled}
            required={question.mandatory}
          />
        );
    }
  }

  return (
    <>
      { getQuestion() }
      {linkedQuestions?.map((linkedQuestion) => 
        <Question
          key={linkedQuestion.id}
          question={linkedQuestion}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </>
  )

}

export default Question;
