import { API_NET_ZERO_PLAN } from "../constants";
import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { ImpactArea, ImpactAreas, mapImpactAreas } from "../types/ImpactArea";
import { Activity, mapActivities } from "../types/Activity";
import { FocusGroup, mapFocusGroups } from "../types/FocusGroup";
import { formatCompanyIdQueryString } from "../helpers/formatCompanyId";

export const getImpactArea = (id: number, netZeroPlanId: number, companyId: number): Promise<ImpactArea> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetImpactArea?netZeroPlanId=${netZeroPlanId}&impactAreaId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapImpactAreas([res.payload])[0] as ImpactArea;
    });
}

export const getActivity = (id: number, netZeroPlanId: number, companyId: number): Promise<Activity> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetActivity?netZeroPlanId=${netZeroPlanId}&activityId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActivities([res.payload])[0] as Activity;
    });
}

export const getFocusGroup = (id: number, netZeroPlanId: number, companyId: number): Promise<FocusGroup> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetFocusGroup?netZeroPlanId=${netZeroPlanId}&focusGroupId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapFocusGroups([res.payload])[0] as FocusGroup;
    });
}

export const getMyCarbonFootprintDetail = (type: number, id: number, netZeroPlanId: number, companyId: number) => {
  switch (type) {
    case 1:
      return getFocusGroup(id, netZeroPlanId, companyId);
    case 2:
      return getActivity(id, netZeroPlanId, companyId);
    case 0:
    default:
      return getImpactArea(id, netZeroPlanId, companyId);
  }
}

export const getAllImpactArea = (id: number | null, companyId: number, comparisonYear?: number): Promise<ImpactAreas> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetImpactAreas?netZeroPlanId=${id ?? ''}&${formatCompanyIdQueryString(companyId)}&comparisonYear=${comparisonYear}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapImpactAreas(res.payload) as ImpactAreas;
    });
}
