import { RAGStatusType } from "../components/RAGStatus";
import { Charts } from "./Chart";
import { onContentUpdate } from "./ContentUpdate";
import { Activity } from "./DataPeriod";
import { Location } from "./Location";

export const documentTypes = [
  "Carbon Report",
  "Commitment Statement",
  "Progress Report",
  "Route To Net Zero",
  "Responsible Business Report",
  "Certificate",
  "Other"
];
export type DocumentType = typeof documentTypes[number];

export type CompanyReport = {
  reportId: number;
  title: string;
  status: string;
  totaltCO2e: number;
  yearEnds: string;
  hidden: boolean;
  reportTypeString: string;
  checkStatus?: RAGStatusType;
  reportValidation?: ReportValidation;
  surveyIds?: number[];
};

export type CompanyDocument = {
  id: number | null;
  title: string;
  carbonReportUrl: string;
  reportType: number;
  reportTypeString: string;
  file?: File,
  fileType?: string,
};

export type CompanyReports = CompanyReport[];
export type CompanyDocuments = CompanyDocument[];

export type CompanyReportAndDocument = CompanyReport | CompanyDocument;
export type CompanyReportsAndDocuments = CompanyReportAndDocument[];

export interface Page {
  pageId: number;
  pageNumber: number;
  pageTitle: string;
  pageSection: string;
  pageSubTitle: string;
  type: string;
  active: boolean;
  details: Detail[];
  charts: Charts;
  footer: EditorContent;
}

export interface ReportExtras {
  totaltCo2e: number;
  totalEquivalentDriving: number;
  totalEquivalentFlying: number;
  tCO2ePerFTE: number;
  averageCommuteMiles: number;
  scope1Percent: number;
  scope1tCO2e: number;
  scope2Percent: number;
  scope2tCO2e: number;
  scope3Percent: number;
  scope3tCO2e: number;
  carTravelEmissions: number;
  averagePerFTE: number;
  latest: ReportExtras;
}

export interface Report {
  id: number;
  surveyIds: number[];
  companyName: string;
  companyLogoUrl: string;
  title: string;
  status: number;
  dateCreated: Date;
  pages: Page[];
  extras: ReportExtras;
}

export type CreateReport = {
  companyId?: string | number;
  title?: string;
  reportType?: number;
  baselineYear?: number;
  baselineDataPeriodIds?: (number | string)[]

  // TODO: Might need to be new type
  crpType?: number;
  description?: string;
  outputType?: number;
};

export type CreateReportReturn = {
  reportId: string | number;
  reportValidation: ReportValidation;
}

export type EditorContentType =
  | 'text'
  | 'ordered-list'
  | 'unordered-list'
  | 'custom';

export interface EditorContent {
  boxAccent?: boolean;
  boxAccentColor?: string;
  header?: string;
  content?: string[];
  type?: EditorContentType;
}

export interface Detail {
  id: number;
  comments: EditorContent;
  imageUrl: string;
}

export interface ReportPageProps {
  report: Report;
  page: Page;
  onContentUpdate: onContentUpdate;
  className?: string;
  downloadVersion?: boolean;
  editable?: boolean;
};

type YearEndsWithReport = {
  id: number;
  title: string;
  status: number;
  dateCreated: Date;
  lastUpdatedDate: Date;
  lastUpdatedBy: number;
  companyID: number;
};

export type YearEndsWithReportStatus = {
  companyInfoId: number;
  companyId: number;
  office: string;
  dateFrom: Date;
  dateTo: Date;
  reports: YearEndsWithReport[];
};

export type SurveyAnswer = {
  slug: string;
  value?: string;
}

export type SurveyAnswers = SurveyAnswer[];

export const reportTypes = [
  "Baseline",
  "Annual",
  "Carbon Reduction Plan"
];

export const outputTypes = [
  "PowerPoint",
  "PDF",
];

export const crpTypes = [
  "PPN 06/21",
];

export type ReportValidation = {
  activity: ReportValidationDetail;
  location: ReportValidationDetail;
  surveyLocation: ReportValidationDetail;
  surveyResponse: ReportValidationOutlier;
  overallStatus?: RAGStatusType;
}

export type ReportValidationDetail = {
  total: number;
  expectedCount: number;
  extra: (Activity | Location)[];
  missing: (Activity | Location)[];
  status?: RAGStatusType;
}

export type ReportValidationOutlier = {
  scoreAverage: number;
  scoreOutliers: any[];
  status?: RAGStatusType;
  [key: string]: any;
}

export const mapCompanyReports = (data: Partial<CompanyReport>[] = []): CompanyReports => {
  return data.map((item: Partial<CompanyReport>) => {
    const reportValidation = mapReportValidation(item.reportValidation);
    return {
      ...item,
      reportTypeString: "Carbon Report",
      reportValidation,
      checkStatus: reportValidation.overallStatus
    }
  }) as CompanyReports;
};

export const mapCreateReportReturn = (data: Partial<CreateReportReturn> = {}): CreateReportReturn => {
  return {
    ...data,
    reportValidation: mapReportValidation(data.reportValidation)
  } as CreateReportReturn;
};

export const mapReportValidation = (data: Partial<ReportValidation> = {}): ReportValidation => {
  let overallStatus = 'red';

  // Map activity data
  const activity = mapReportValidationDetail(data?.activity || undefined);

  // Map location data
  const location = mapReportValidationDetail(data?.location);

  // Map survey location data
  const surveyLocation = mapReportValidationDetail({
    expectedCount: data?.surveyResponse?.expectedLocationCount,
    extra: data?.surveyResponse?.extraLocations,
    missing: data?.surveyResponse?.missingLocations
  });

  // Map survey response data
  const surveyResponse = mapReportValidationOutlier(data?.surveyResponse);

  if ([activity.status, location.status, surveyLocation.status, surveyResponse.status].includes('red')) {
    overallStatus = 'red';
  } else if ([activity.status, location.status, surveyLocation.status, surveyResponse.status].includes('amber')) {
    overallStatus = 'amber';
  } else {
    overallStatus = 'green';
  }

  return {
    ...data,
    overallStatus,
    activity,
    location,
    surveyLocation,
    surveyResponse
  } as ReportValidation;
};

export const mapReportValidationDetail = (data: Partial<ReportValidationDetail> = {}): ReportValidationDetail => {
  let total = (data.expectedCount || 0) + (data.extra?.length || 0) + (data.missing?.length || 0);
  let status = (data.missing?.length || 0) > 0 ? 'red' : 'green';

  return {
    ...data,
    total,
    status
  } as ReportValidationDetail;
}

export const mapReportValidationOutlier = (data: Partial<ReportValidationOutlier> = {}): ReportValidationOutlier => {
  let status = (data.scoreOutliers?.length || 0) > 0 ? 'red' : 'green';

  return {
    ...data,
    status
  } as ReportValidationOutlier;
}