import { API_DATA_PERIOD } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { formatCompanyId, formatCompanyIdQueryString } from "../../helpers/formatCompanyId";
import { ActivityData, ActivityNote, AuditDataPeriod, DataPeriod, DataPeriodDates, DataPeriodQuestions, DataPeriods, DataPeriodTabs, LocationQuestion, LocationQuestions, mapDataPeriodDates, mapDataPeriods, mapDataPeriodTabs, SupplierMeasureData } from "../../types/DataPeriod";
import { Locations, mapLocations } from "../../types/Location";
import { mapQuestionGroups, Submissions } from "../../types/Question";
import { dedupeFetch } from "../dedupeFetch";

export const getDataPeriodsBasic = (id: number): Promise<DataPeriods> => {
  return dedupeFetch(`${API_DATA_PERIOD}/GetAllBasic?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriods(res.payload));
}

export const getDataPeriods = (id: number): Promise<DataPeriods> => {
  return dedupeFetch(`${API_DATA_PERIOD}/GetAll?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriods(res.payload));
}

export const getDataPeriod = (id: string, companyId: number | string): Promise<DataPeriod> => {
  return fetch(`${API_DATA_PERIOD}/Get?dataPeriodId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriods([res.payload])[0]);
}

export const getAudits = (id: string, companyId: number | string): Promise<AuditDataPeriod[]> => {
  return fetch(`${API_DATA_PERIOD}/GetAudits?dataPeriodId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}

export const getAuditsCSV = async (id: string, companyId: number | string): Promise<Blob> => {
  return await fetch(`${API_DATA_PERIOD}/GetAuditsAsCsv?dataPeriodId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'GET',
  })
  .then((res) => res.blob());
};

export const createDataPeriod = (id: string, dataPeriod: DataPeriod): Promise<DataPeriod> => {
  return fetch(`${API_DATA_PERIOD}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      ...dataPeriod,
      companyId: formatCompanyId(id)
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as DataPeriod;
    });
}

export const deleteDataPeriod = (id: string) => {
  return fetch(`${API_DATA_PERIOD}/Delete?dataPeriodId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors);
}

export const getTabQuestions = (id: string, companyId: number | string): Promise<DataPeriodTabs> => {
  return fetch(`${API_DATA_PERIOD}/GetTabs?dataPeriodId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriodTabs(res.payload, Number(id)));
}

export const updateDataPeriodWithEmissionData = (id: string, dataPeriod: DataPeriod, emissionData: ActivityData = []): Promise<DataPeriodTabs> => {
  const measureData: { activityId: number; measureId: number; measureValue: number | undefined; supplierMeasureData: SupplierMeasureData; }[] = [];
  const locationMeasureData: { locationId: number; activityId: number; measureId: number; measureValue: number | undefined; supplierMeasureData: SupplierMeasureData; }[] = [];

  emissionData.forEach(dataItem => {
    const data = {
      activityId: dataItem.activityId,
      measureId: dataItem.measureId,
      measureValue: dataItem.value ?? undefined,
      supplierMeasureData: dataItem.supplierMeasureData,
      wasEstimation: dataItem.isEstimation,
      isEstimation: dataItem.isEstimation,
      submittedAt: dataItem.submittedAt
    };
    if (dataItem.locationId) {
      locationMeasureData.push({
        ...data,
        locationId: dataItem.locationId
      });
    } else {
      measureData.push(data);
    }
  });

  return fetch(`${API_DATA_PERIOD}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      dataPeriodId: Number(id),
      ...dataPeriod,
      ...( measureData.length > 0 ? { measureData: measureData } : {}),
      ...( locationMeasureData.length > 0 ? { locationMeasureData: locationMeasureData } : {})
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriodTabs(res.payload, Number(id)));
}

export const getDataPeriodDates = (id: string, years: number = 2, showAll: boolean = false): Promise<DataPeriodDates> => {
  return fetch(`${API_DATA_PERIOD}/GetDataPeriodDates?${formatCompanyIdQueryString(id)}&YearsToGoBack=${years}&ShowAll=${showAll}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriodDates(res.payload));
}

export const getAvailableBaselineYears = (id: string): Promise<string[]> => {
  return fetch(`${API_DATA_PERIOD}/GetAvailableBaselineYears?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(res => res.payload);
}

export const getValidateBaselineYearDataPeriods = (id: string, baselineYear: number): Promise<DataPeriods> => {
  return fetch(`${API_DATA_PERIOD}/ValidateBaselineYearDataPeriods?BaselineYear=${baselineYear}&CompanyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then(res => res.payload);
}

export const submitDataPeriod = (id: string, signature: string, companyId: number | string): Promise<boolean> => {
  return fetch(`${API_DATA_PERIOD}/Submit`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      dataPeriodId: Number(id),
      companyId: formatCompanyId(companyId),
      signature 
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.success;
    });
}

export const reopenDataPeriod = (id: string, companyId: number | string): Promise<DataPeriod | undefined> => {
  return fetch(`${API_DATA_PERIOD}/Reopen`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      dataPeriodId: id,
      companyId
    })
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as DataPeriod;
    });
}

export const updateActivityNote = (id: number | string, note: ActivityNote, companyId: number | string): Promise<ActivityNote> => {
  const data = new FormData();
  var formattedCompanyId = formatCompanyId(companyId || 0);
  if(formattedCompanyId) data.append('companyId', formattedCompanyId.toString());
  data.append('dataPeriodId', String(id));
  data.append('activityId', String(note.activityId));
  note.locationId && data.append('locationId', String(note.locationId));
  data.append('notes', note.notes || '');
  if (note.file) {
    data.append('file', note.file);
  } else {
    note.documentationUrl && data.append('documentationUrl', note.documentationUrl);
  }
  return fetch(`${API_DATA_PERIOD}/SubmitActivityNote`, {
    headers: { "X-CSRF": "1" },
    method: 'POST',
    body: data
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      if (res.success) {
        return {
          ...note,
          ...res.payload
        };
      } else {
        throw new Error(res.message);
      }
    });
}

export const getDataPeriodQuestions = (id: string, companyId: number | string): Promise<DataPeriodQuestions> => {
  return fetch(`${API_DATA_PERIOD}/GetQuestions?dataPeriodId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapDataPeriodQuestions(res.payload));
}

const mapDataPeriodQuestions = (results: Partial<DataPeriodQuestions>): DataPeriodQuestions => {
  return {
    locationQuestions: mapLocationQuestions(results.locations || [], results.locationQuestions ?? []),
    questions: mapQuestionGroups(results.questions ?? []),
    locations: mapLocations(results.locations ?? [])
  };
}

const mapLocationQuestions = (_locations: Locations, _questions: any[]): LocationQuestions => {
  return _locations.map((_location) => ({
    location: mapLocations([_location])[0],
    questions: mapQuestionGroups(_questions ?? [], _location.id),
  } as LocationQuestion));
}

export const submitDataPeriodQuestionAnswers = (id: string | number, submissions: Submissions = [], locationSubmissions: Submissions = [], companyId: string | number): Promise<boolean> => {
  var groups = [] as {
    locationId: number,
    questionAnswers: {
      questionAnswerId: number,
      freeTextAnswer?: string
    }[]
  }[];

  locationSubmissions.forEach(ls => {
    var match = groups.find(g => g.locationId === ls.locationId);

    if(match) {
      match.questionAnswers.push({
        questionAnswerId: ls.questionAnswerId ?? 0,
        freeTextAnswer: ls.freeTextAnswer
      });
    } else {
      groups.push({
        locationId: ls.locationId ?? 0,
        questionAnswers: [{
          questionAnswerId: ls.questionAnswerId ?? 0,
          freeTextAnswer: ls.freeTextAnswer
        }]
      });
    }
  });

  return fetch(`${API_DATA_PERIOD}/SubmitQuestionAnswers`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      companyId: formatCompanyId(companyId),
      dataPeriodId: Number(id),
      questionAnswers: submissions.map((submission) => ({
        questionAnswerId: submission.questionAnswerId,
        freeTextAnswer: submission.freeTextAnswer
      })),
      locationQuestionAnswers: groups
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.success);
}