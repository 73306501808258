import { useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { CreateReport, ReportValidation } from "../../types/Report";
import { createReport as createReportAPI, generateReport as generateReportAPI, validateReport as validateReportAPI } from "../../api/Report";
import { useCompanyId } from "../../context";

const useCreateReport = () => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [reportId, setReportId] = useState<number | string>();
  const [reportValidation, setReportValidation] = useState<ReportValidation>();
  const [loading, setLoading] = useState(false);
  const [createReport, setCreateReport] = useState<CreateReport>({ outputType: 1 });

  const updateCreateReport = (updatedCreateReport: Partial<CreateReport>) => {
    setCreateReport({
      ...createReport,
      ...updatedCreateReport
    });
  };

  const saveCreateReport = async (): Promise<string | void> => {
    if (createReport) {
      setLoading(true);
      if (createReport.reportType === 2) {
        return await generateReportAPI({
            companyId,
            ...createReport
          })
          .then(async (res) => {
            setReportId(res);
            addAlert({ id: `Report Created ${companyId}`, type: "success", title: "Report Created Successfully" });
            await validateReport(res);
            return res as string;
          })
          .catch(() => {
            addAlert({ id: `Report Create Failed ${companyId}`, type: "error", title: "Report Create Unsuccessful", content: "Report failed to create please try again." });
          })
          .finally(() => setLoading(false));
      }
      return await createReportAPI({...createReport, baselineYear: parseInt(createReport.baselineYear?.toString().split(' ')[0] ?? "")})
        .then(async (res) => {
          setReportId(res.reportId);
          setReportValidation(res.reportValidation)
          addAlert({ id: `Report Created ${companyId}`, type: "success", title: "Report Created Successfully" });
          await validateReport(res.reportId);
          return res.reportId as string;
        })
        .catch((e) => {
          var errorMessage = "Report failed to create please try again.";
          if(e?.message?.toLowerCase() == "survey still active.")
            errorMessage = "A report cannot be created while there is an active survey.";

          addAlert({ id: `Report Create Failed ${companyId}`, type: "error", title: "Report Create Unsuccessful", content: errorMessage });
        })
        .finally(() => setLoading(false));
    }
  }

  const validateReport = async (id: string | number) => {
    return await validateReportAPI(id, companyId)
      .then((res) => {
        return res as string;
      })
      .catch(() => {
        addAlert({ id: `Report Validation Failed ${companyId}`, type: "error", title: "Report Validation Unsuccessful", content: "Failed to validate report please try again." });
      })
      .finally(() => setLoading(false));

  }

  useEffect(() => {
    updateCreateReport({
      companyId: companyId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    reportId,
    createReport,
    reportValidation,
    updateCreateReport,
    saveCreateReport,
    validateReport,
    loading
  };
}

export default useCreateReport;

