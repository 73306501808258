import { API_REDUCTION } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { CreateReductionTargets, EmulateReductionTargets, ReductionTarget, ReductionTargets } from "../../types/ReductionTargets";

export const emulateReductionTargets = (data: EmulateReductionTargets, companyId: string): Promise<ReductionTargets> => {
  const {
    scope1ReductionTargetInterim = 50,
    scope2ReductionTargetInterim = 50,
    scope3ReductionTargetInterim = 50,
    scope1ReductionTargetNetZero = 90,
    scope2ReductionTargetNetZero = 90,
    scope3ReductionTargetNetZero = 90
  } = data;
  return fetch(`${API_REDUCTION}/Emulate`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      ...data,
      scope1ReductionTargetInterim: scope1ReductionTargetInterim / 100,
      scope2ReductionTargetInterim: scope2ReductionTargetInterim / 100,
      scope3ReductionTargetInterim: scope3ReductionTargetInterim / 100,
      scope1ReductionTargetNetZero: scope1ReductionTargetNetZero / 100,
      scope2ReductionTargetNetZero: scope2ReductionTargetNetZero / 100,
      scope3ReductionTargetNetZero: scope3ReductionTargetNetZero / 100,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload.map((item: ReductionTarget) => ({
        ...item,
        scope: item.scopeId
      })) as ReductionTargets;
    });
};


export const createReductionTargets = (data: CreateReductionTargets, companyId: string): Promise<ReductionTargets> => {
  const {
    scope1ReductionTargetInterim = 50,
    scope2ReductionTargetInterim = 50,
    scope3ReductionTargetInterim = 50,
    scope1ReductionTargetNetZero = 90,
    scope2ReductionTargetNetZero = 90,
    scope3ReductionTargetNetZero = 90
  } = data;
  return fetch(`${API_REDUCTION}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      netZeroPlanId: data.netZeroPlanId,
      scope1ReductionTargetInterim: scope1ReductionTargetInterim / 100,
      scope2ReductionTargetInterim: scope2ReductionTargetInterim / 100,
      scope3ReductionTargetInterim: scope3ReductionTargetInterim / 100,
      scope1ReductionTargetNetZero: scope1ReductionTargetNetZero / 100,
      scope2ReductionTargetNetZero: scope2ReductionTargetNetZero / 100,
      scope3ReductionTargetNetZero: scope3ReductionTargetNetZero / 100,
      companyId
    }),
  })
    .then((res) => checkAuthorised(res));
};