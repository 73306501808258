import './styles.scss';

import { Fragment, useEffect, useState } from "react";
import { Blocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Card, DatalistMulti, Divider, IconButton, Label, Options, SaveIcon, Spinner, Tag, WrenchIcon, useAlert } from "@flotilla/component-library";

import { getDataIntegrations, updateAccountsNominals } from '../../../api/DataIntegration';
import { DATA_INTEGRATION_MAPPING_SUBTITLE } from '../../../assets/content/Subtitles';
import { ACCOUNTS_MAPPING_INFO_ALERT_MESSAGE } from '../../../assets/content/AlertMessages';
import Header from "../../../components/HeaderV2";
import SaveChangesModal from '../../../components/Modal/SaveChangesModal';
import { useCompanyId } from "../../../context";
import { useCompany } from "../../../hooks";
import { Activity } from '../../../types/DataPeriod';
import { AccountsNominal, AccountsNominals, DataIntegration } from '../../../types/DataIntegrations';
import useAccountMappings from '../../../hooks/AccountMappings';

interface AccountsMappingProps {};

const AccountsMapping: React.FC<AccountsMappingProps> = () => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [isSaving, setIsSaving] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { integrationId } = useParams();
  const { key, state } = useLocation();

  const [remainingNominals, setRemainingNominals] = useState<AccountsNominals>();
  const [integration, setIntegration] = useState<DataIntegration | undefined>(state?.dataIntegration);
  const {
    accountNominals,
    activities,
    loading,
    pendingSync,
    setActivities
  } = useAccountMappings(integrationId);

  useEffect(() => {
    if(company?.id && integrationId) {
      !state?.dataIntegration && getDataIntegrations(company?.id)
        .then((res) => {
          setIntegration(res.find(r => r.id === parseInt(integrationId)));
        })
        .catch(() => {
          addAlert({ id: `Failed To Retreive Integration Details`, type: "error", title: "Failed to load integration details", content: "Failed to load the data integration details, please refresh to try again." });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleSave = (navigationBlocker?: Blocker) => {
    if(!loading && !isSaving && accountNominals) {
      setIsSaving(true);
      let completedNominals: AccountsNominals = [];

      activities?.forEach((_activity) => {
        const _nominals = _activity?.nominals?.map((item) => ({
          ...item,
          activityId: _activity.id
        })) || [] as AccountsNominals;
        completedNominals.push(..._nominals);
      });
      updateAccountsNominals(completedNominals, company.id)
        .then(() => {
          addAlert({ id: `Account Nominal Mappings Saved`, type: "success", title: "Mappings saved successfully" });
          navigationBlocker?.proceed?.();
        })
        .catch(() => {
          addAlert({ id: `Account Nominal Mappings Failed To Save`, type: "error", title: "Saving mappings unsuccessful", content: "Failed to save mappings, please try again." });
        })
        .finally(() => {
          setIsSaving(false);
          setUpdated(false);
        });
    }
  };

  const handleOnChangeMapping = (activity: Activity) => {
    return (options?: Options) => {
      setActivities((prev) => prev?.map((item) => {
        if (item.id === activity.id) {
          return {
            ...item,
            nominals: options as unknown as AccountsNominals
          }
        }
        
        return item;
      }));
      setUpdated(true);
    }
  }

  const updateRemainingNominals = () => {
    let remainingNominals: AccountsNominals = accountNominals || [];

    activities?.forEach((activity) => {
      const nominalIds = activity?.nominals?.map((item) => item.thirdPartyId) || [];
      remainingNominals = remainingNominals.filter((item) => !nominalIds.includes(item.thirdPartyId));
    });
  
    setRemainingNominals(remainingNominals);
  }

  const suggestedNominalsSort = (_activity: Activity) => {
    return (_a: AccountsNominal, _b: AccountsNominal): number => {
      const aMatch = _a.matches?.find((item) => item.entity.id === _activity.id)?.quality || -1;
      const bMatch = _b.matches?.find((item) => item.entity.id === _activity.id)?.quality || -1;
  
      return aMatch < bMatch ? 1 : -1;
    }
  }

  useEffect(() => {
    updateRemainingNominals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, accountNominals]);

  return (
    <section id="accounts-mapping-page">
      <Header
        showBackButton
        onBackClick={!key ? () => navigate(Number(companyId) > 0 ? `/${companyId}` : '') : undefined}
        rightChildren={() =>
          <IconButton
            icon={<SaveIcon />}
            onClick={() => handleSave()}
            isLoading={loading || isSaving}
          />
        }
        subtitle={DATA_INTEGRATION_MAPPING_SUBTITLE.replaceAll("%%ACCOUNTS_NAME%%", integration?.name ?? "Accounts")}
      >
        {integration?.name ?? "Accounts"} Connection
      </Header>
      {loading ? (
        <Spinner size='large' lightBackground/>
      ) : (
        (pendingSync || !accountNominals?.length) ? (
          <main className='pending-data'>
            <WrenchIcon variant='brand' width={60} height={60} />
            { pendingSync ? (
              <>
                <h4>Your finance data is still being synced.</h4>
                <p>Please check back soon.</p>
              </>
            ) : (
              <>  
                <h4>No account nominals were found.</h4>
                <p>Please ensure your accounts package is correctly configured.</p>
              </>
            )}
          </main>
        ) : (
          <main>
            <Alert
              id="info-message"
              variant='outline'
              title={
                <>
                  {ACCOUNTS_MAPPING_INFO_ALERT_MESSAGE.replaceAll("%%ACCOUNTS_NAME%%", integration?.name ?? "Accounts").split("<br/>").map((item) => <><br/><p>{item}</p></>)}
                </>
              }
            />
            <section id="content">
              <article id="groupings-matcher">
                <header>
                  <label className='header'><h3>Flotilla Activity</h3></label>
                  <label className='header'><h3>{integration?.name ?? "Accounts"} expenses</h3></label>
                </header>
                <Divider id='header-divider' />
                { activities?.map((activity, index) => {
                  return (
                    <Fragment key={index}>
                      <section className='grouping'>
                        <Label className='label' title={activity.name} tooltip={activity.mappingTooltip ? { tooltipText: activity.mappingTooltip } : undefined}>{activity.name}</Label>
                        <DatalistMulti
                          id={`activity-${activity.name}`}
                          key={activity.name}
                          options={remainingNominals?.sort(suggestedNominalsSort(activity))?.map((item) => ({
                            value: item.accountName,
                            label: item.accountName,
                            searchTerms: item.accountName,
                            ...item
                          })) as Options}
                          onChange={handleOnChangeMapping(activity)}
                          values={activity.nominals?.map((_nominal) => {
                            const {
                              matches = []
                            } = _nominal;
                            const predicted = matches.find((item) => (item.entity as Activity).id === activity.id)?.quality || 0;
                            const alertType = predicted > 0.7 ? 'success' : predicted > 0.4 ? 'warning' : predicted > 0 ? 'error' : undefined;
                            return {
                              value: _nominal.accountName,
                              label: _nominal.accountName,
                              searchTerms: _nominal.accountName,
                              alertType: alertType,
                              ..._nominal
                            }
                          })}
                          searchKeys={['value', 'label', 'searchTerms']}
                        />
                      </section>
                      <Divider />
                    </Fragment>
                  )
                })}
              </article>
              <aside>
                <Card id="groupings-list">
                  <h3>Your expenses</h3>
                  <p id="subtitle">{remainingNominals?.length || 0} of {accountNominals.length} to be mapped</p>
                  <section id="groupings">
                    {remainingNominals?.map(an => (
                      <Tag grey key={an.thirdPartyId}>{an.accountName}</Tag>
                    ))}
                  </section>
                </Card>
              </aside>
            </section>
          </main>
        )
      )}
      <SaveChangesModal
        condition={updated}
        onSave={handleSave}            
      />
    </section>
  );
}

export default AccountsMapping;