import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { getDataPeriodQuestions, submitDataPeriodQuestionAnswers } from "../../api/DataPeriod";
import { useCompanyId } from "../../context";
import { DataPeriodQuestions, LocationQuestions } from "../../types/DataPeriod";
import { Answer, Question, QuestionGroup, Submission, Submissions } from "../../types/Question";

const useDataPeriodSettingsQuestions = (id?: string) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<DataPeriodQuestions>();
  const [submissions, setSubmissions] = useState<Submissions>();
  const [locationSubmissions, setLocationSubmissions] = useState<Submissions>();
  const [updatedSubmissions, setUpdatedSubmissions] = useState(false);

  const fetchApi = useCallback(async () => {
    if (id && companyId) {
      setLoading(true);
      await getDataPeriodQuestions(id, companyId)
        .then((res) => {
          setQuestions(res);
          setSubmissions(res.questions
            .flatMap(q => getQuestionSubmissions(q))
            .filter((v, index, self) => self.findIndex(s => s.id === v.id) === index)
            ?? []);
          setLocationSubmissions(getLocationSubmissions(res.locationQuestions));
        })
        .catch((error) => {
          addAlert({ id: `Data Period Settings Load Failed ${companyId}`, type: "error", title: "Data Period Settings Unsuccessful", content: "Data Period Settings failed to load please try again." });
          console.log("Data Period Settings Failed to load, please try again:", error);
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, id]);
  
  const getLocationSubmissions = (locationQuestions: LocationQuestions) : Submissions => {
    const _locationSubmissions: Submissions = [];

    locationQuestions.forEach((_locationQuestion) => {
      const _submissions = _locationQuestion.questions
          .flatMap(q => getQuestionSubmissions(q))
          .filter((v, index, self) => self.findIndex(s => s.id === v.id) === index)
      _locationSubmissions.push(..._submissions);
    });

    return _locationSubmissions
  }

  const getQuestionSubmissions = (questionGroup: QuestionGroup) : Submissions => {
    return questionGroup.questions
      .flatMap(q => getSingleQuestionSubmissions(q));
  }

  const getSingleQuestionSubmissions = (question: Question) : Submissions => {
    return question.questionAnswers
      .flatMap(a => {
        return getAnswerSubmissions(a, question.id);
      });
  }
  
  const getAnswerSubmissions = (answer: Answer, questionId: number) : Submissions => {
    return (answer.questionAnswerSubmissions?.map(s => {
      return { 
        id: s.id,
        questionAnswerId: s.questionAnswerId,
        freeTextAnswer: s.freeTextAnswer,
        questionId: questionId,
        locationId: s.locationId,
      } as Submission
    }) ?? []).concat(answer.linkedQuestion ? getSingleQuestionSubmissions(answer.linkedQuestion) : []);
  }
    
  const refreshQuestions = () => {
    fetchApi();
  }

  const updateSubmissions = (questionId: number, updatedSubmissions: Submissions = []) => {
    setUpdatedSubmissions(true);
    setSubmissions((prev) => ([
      ...(prev?.filter((submission) => submission.questionId !== questionId) || []),
      ...updatedSubmissions.map((item) => ({ ...item, questionId}))
    ]));
  };

  const updateLocationSubmissions = (locationId: number, questionId: number, updatedSubmissions: Submissions = []) => {
    setUpdatedSubmissions(true);
    setLocationSubmissions((prev) => {
      return [
        ...(prev?.filter((submission) => !(submission.questionId === questionId && submission.locationId === locationId)) || []),
        ...updatedSubmissions.map((item) => ({ ...item, locationId, questionId}))
      ]
    });
  };
  
  const saveSubmissions = async () => {
    if (id) {
      setLoading(true);
      await submitDataPeriodQuestionAnswers(id, submissions, locationSubmissions, companyId)
        .finally(() => {
          setLoading(false);
          setUpdatedSubmissions(false);
        });
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    }
  }, [companyId, fetchApi, id]);

  return {
    questions,
    updateSubmissions,
    refreshQuestions,
    saveSubmissions,
    updatedSubmissions,
    updateLocationSubmissions,
    loading
  };
}

export default useDataPeriodSettingsQuestions;
