  import './styles.scss';

import React from 'react';
import { Card, CardProps, CloseIcon, Divider, IconButton, Spinner, Tag, Tooltip, WarningIcon } from '@flotilla/component-library';

import { Action } from '../../types/Action';
import getRatingIcon from '../../helpers/getRatingsIcon';
import ImpactAreaTag from '../ImpactAreaTag';
import { Link } from 'react-router-dom';
import LocationTag from '../LocationTag';

interface ActionCardProps extends CardProps {
  className?: string;
  action: Action;
  loading?: boolean;
  reducedDetail?: boolean;
  targetProducts?: string[];
  onClose?: () => void;
};

const ActionCard: React.FC<ActionCardProps> = ({
  className = "",
  action,
  onClick = () => ({}),
  onClose,
  loading = false,
  reducedDetail = false,
  targetProducts,
  ...props
}) => {
  const {
    id,
    title,
    impactArea,
    departments,
    carbonSavingsPotential = "none",
    coBenefitsPotential = "none",
    costSavingsPotential = "none",
    activityName,
    products,
    netZeroPlanAction
  } = action;
  const sentenceCase = (value: string) => {
    const initialChar = value.slice(0, 1);
    const slicedValue = value.slice(1);
    return `${initialChar.toUpperCase()}${slicedValue}`;
  }

  const invalid = onClose && targetProducts?.length && !products?.find(p => targetProducts?.includes(p.label))

  return (
    <Card
      id="action-card"
      className={`action-card action-card--${impactArea?.name.toLowerCase().replaceAll(' ', '-')} ${reducedDetail ? 'action-card--reduced-detail' : ''} ${invalid ? 'action-card--error' : ''} ${className}`}
      onClick={onClick}
      {...props}
    >
      <header>
        {netZeroPlanAction?.id ? 
          <Link className='title-link' to={`${id}?version=${netZeroPlanAction?.id}`} onClick={(e) => e.stopPropagation()}>{title}</Link> :
          <p className='title-link'>{title}</p>
        }
        { loading && <Spinner size='small' lightBackground/>}
        { onClose && <IconButton id="close-button" small icon={<CloseIcon variant='dark' small />} variant='ghost' onClick={onClose}/>}
      </header>
      {!reducedDetail ? (
        <>
          <section id="tags">
            { impactArea &&
              <ImpactAreaTag
                size="small"
                extraText={activityName}
              >
                {impactArea.name}
              </ImpactAreaTag>
            }
            { departments.map((department, index) => <Tag key={index} className="department-tag tag" size="small">{department.name}</Tag>) }
            { netZeroPlanAction?.location && <LocationTag location={netZeroPlanAction?.location} size="small" /> }
          </section>
          <section id="stats">
            <section className="stat">
              { getRatingIcon(carbonSavingsPotential, "cloud") }
              <p>{sentenceCase(carbonSavingsPotential)}</p>
            </section>
            <Divider />
            <section className="stat">
              { getRatingIcon(coBenefitsPotential, "hand") }
              <p>{sentenceCase(coBenefitsPotential)}</p>
            </section>
            <Divider />
            <section className="stat">
              { getRatingIcon(costSavingsPotential, "money") }
              <p>{sentenceCase(costSavingsPotential)}</p>
            </section>
          </section>
        </>
      ) : <section id="tags">
        {products?.map(p => <Tag>{p.label}</Tag>)}
      </section>}
      {invalid ? 
        <Tooltip icon={() => <WarningIcon fill='#ff4040'/>} alignment='left'>
          This action isn't available to the same product(s) as the current action.
        </Tooltip>
      : <></>}
    </Card>
  );
}

export default ActionCard;
