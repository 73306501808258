import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Skeleton, useAlert } from '@flotilla/component-library';

import { getNetZeroPlanId } from '../../../../../reducers/user';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/hooks';
import { Action, Actions } from '../../../../../types/Action';
import ActionCard from '../../../../../components/ActionCard';
import { updateAction } from '../../../../../api/netZeroPlan';
import { useCompanyId, useUserAccess } from '../../../../../context';
import { AddActionModal } from '../../../../../components/Modal';
import { addModal, removeModal } from '../../../../../reducers/modal';

interface BoardViewProps {
  className?: string;
  data: Actions;
  onChange?: (action: Action) => void;
  loading?: boolean;
  onClick?: (action: Action) => void;
  onRefresh?: () => void;
};

const statuses = ["To do", "Active", "Overdue", "Completed"];

const BoardView: React.FC<BoardViewProps> = ({
  className = "",
  data: initialData,
  loading = false,
  onChange = () => ({}),
  onClick = () => ({}),
  onRefresh = () => ({})
}) => {
  const dispatch = useAppDispatch();
  const { addAlert } = useAlert()
  const companyId = useCompanyId();
  const { userAccess } = useUserAccess();
  const { NET_ZERO_PLAN_EDIT } = userAccess;
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [data, setData] = useState<Actions>(initialData);
  const [dragAction, setDragAction] = useState<Action>();
  const [actionLoading, setActionLoading] = useState<number[]>([]);

  useEffect(() => {
    initialData && setData(initialData);
  }, [initialData]);

  const handleStatusDragOver: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  }

  const handleUpdateAction = async (action: Action, originalAction?: Action) => {
    if (action?.netZeroPlanAction?.id && netZeroPlanId) {
      setActionLoading([...actionLoading, action?.netZeroPlanAction?.id]);
      await updateAction({
        ...action?.netZeroPlanAction,
        status: action.status || "To do"
      }, companyId)
      .then(() => {
        onChange(action);
        if (action.isRecurring && action.status === 'Completed') {
          dispatch(addModal({
            id: 'add-action',
            children: (
              <AddActionModal
                action={{
                  ...action,
                  companyId: companyId
                }}
                netZeroPlanId={netZeroPlanId}
                onClose={() => dispatch(removeModal())}
                onSubmit={onRefresh}
                header="Readd this recurring action to plan"
              />
            )
          }));
        }
      })
      .finally(() => {
        setActionLoading(actionLoading.filter((item) => item !== action?.netZeroPlanAction?.id));
      })
      .catch((error) => {
        addAlert({
          id: `Error Updating Action ${action?.netZeroPlanAction?.id}`,
          type: 'error',
          title: 'Error Updating Action',
          content: error.toString(),
        });
        console.log("Error, when updating Action", error);
        originalAction && handleActionsUpdate(originalAction);
      });
    }
  }

  const handleStatusDrop = (status: string): React.DragEventHandler<HTMLElement> => {
    return (event) => {
      event.preventDefault();
      const updatedDragAction = {
        ...dragAction,
        status: status
      } as Action;
      handleActionsUpdate(updatedDragAction);
      handleUpdateAction(updatedDragAction, dragAction);
    }
  }

  const handleActionsUpdate = (updatedAction: Action) => {
    const updatedActions = [...(data || [])].map((item) => {
      if (dragAction && item?.netZeroPlanAction?.id === dragAction?.netZeroPlanAction?.id) {
        return updatedAction;
      }
      return item;
    });
    setData(updatedActions);
  }

  const handleActionDragStart = (action: Action) => {
    return () => {
      setDragAction(action);
    }
  }

  const handleActionDragEnd = () => {
    setDragAction(undefined);
  }

  const handleActionClick = (action: Action): React.MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(action);
    }
  }

  return (
    <section id="board-view" className={className}>
      { statuses.map((status, index) => {
        const actions = data?.filter((item) => item.status === status);
        return (
          <section
            key={index}
            id={`${status?.toLowerCase()}-column`}
            className="status-column"
            { ...(status !== "Overdue" ? {
              onDrop: handleStatusDrop(status),
              onDragOver: handleStatusDragOver
            } : {})}
          >
            <header>
              <p>{status}</p>
              { actions?.length > 0 && <p>{actions.length}</p> }
            </header>
            <main>
              { !loading && data?.length > 0 ? (
                actions?.map((action) => (
                  <ActionCard
                    key={action?.netZeroPlanAction?.id}
                    className={`action ${action?.netZeroPlanAction?.id === dragAction?.netZeroPlanAction?.id ? 'action--dragging' : ''}`}
                    { ...(status !== "Overdue" ? {
                      draggable: NET_ZERO_PLAN_EDIT,
                      onDragStart: handleActionDragStart(action),
                      onDragEnd: handleActionDragEnd
                    } : {})}
                    action={action}
                    onClick={handleActionClick(action as unknown as Action)}
                    loading={actionLoading.includes(action?.netZeroPlanAction?.id || -1)}
                  />
                ))
              ) : (
                <Skeleton className="action--loading"/>
              )}
            </main>
          </section>
        );
      })}
    </section>
  );
}

export default BoardView;
