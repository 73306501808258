import './styles.scss';

import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AddIcon, IconButton, InfoIcon, Menu, MenuItem, Table, Tooltip } from '@flotilla/component-library';

import { getReportDocuments, getReports } from '../../../../api/Report';
import { CompanyDocument, CompanyReport, CompanyReportsAndDocuments, documentTypes } from '../../../../types/Report';
import ReportListItem from './ReportListItem';
import { useCompany } from '../../../../hooks';
import { useCompanyId, useUserAccess } from '../../../../context';
import Header from '../../../../components/HeaderV2';
import DocumentModal from './DocumentModal';
import { DataPeriods } from '../../../../types/DataPeriod';
import { getDataPeriodsBasic } from '../../../../api/DataPeriod';
import useNetZeroPlanId from '../../../../hooks/NetZeroPlan/useNetZeroPlanId';

interface ReportsListProps {
  className?: string;
};

const ReportsList: FC<ReportsListProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const { userAccess } = useUserAccess();
  const { ADMIN, STANDARD, REPORT_CREATE, REPORT_EDIT, PE, CARBON_REDUCTION_PLAN_PRODUCT_ACCESS, CUSTOMER_ADMIN } = userAccess;
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [netZeroPlanId] = useNetZeroPlanId(company?.id);
  const [dataPeriods, setDataPeriods] = useState<DataPeriods>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalDocument, setModalDocument] = useState<CompanyDocument>();
  const [reports, setReports] = useState<CompanyReportsAndDocuments>();
  const canCreateReports = REPORT_CREATE || (CARBON_REDUCTION_PLAN_PRODUCT_ACCESS && CUSTOMER_ADMIN);

  const getToLink = (toLink: string): string => {
    if (ADMIN || !STANDARD) {
      return `/${companyId}${toLink}`;
    }
    return toLink;
  }

  const createReportTooltip = !dataPeriods?.some(dp => dp.status === "Submitted") ? <p>You must have a submitted data period before you can create a report. You can do this by <Link to={getToLink("/data/create")}>clicking here</Link>.</p> :
    !netZeroPlanId ? <p>You must create a net zero plan before you can create a report. You can do this by <Link to={getToLink("/netzeroplan/create")}>clicking here</Link>.</p> : null;
  
  const handleGetReports = (id: string) => {
    setIsLoading(true);
    Promise.all([
      getReports(id),
      getReportDocuments(id)
    ])
      .then((res) => {
        setReports([...res[0].filter((item) => item.status !== "DataSubmitted"), ...res[1]]);
      })
      .finally(() => setIsLoading(false));
  };

  const handleGetDataPeriods = (id: string) => {
    getDataPeriodsBasic(parseInt(id))
      .then((res) => {
        setDataPeriods(res);
      });
  };

  const handleReportClick = (id: number) => {
    navigate(`${id}`);
  }

  useEffect(() => {
    companyId && handleGetDataPeriods(companyId);
    companyId && handleGetReports(companyId);
  }, [companyId]);

  const onClose = () => {
    setIsModalOpen(false);
    setModalDocument(undefined);
    companyId && handleGetReports(companyId);
  }

  const handleOnClickEdit = (data: CompanyDocument) => {
    setIsModalOpen(true);
    setModalDocument(data);
  }

  const handleOnClickAdd = () => {
    if (canCreateReports) {
      setIsMenuOpen(true);
    } else {
      setIsModalOpen(true);
    }
  }

  const handleOnClickDelete = () => {
    companyId && handleGetReports(companyId);
  }

  const handleOnClickCreateReport = () => {
    if(createReportTooltip) return;
    navigate('create')
    setIsMenuOpen(false);
  }

  const handleOnClickUploadDocument = () => {
    setIsModalOpen(true);
    setIsMenuOpen(false);
  }

  const handleOnCloseMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <section id="reports-page" className={className}>
      <Header
        rightChildren={
          () => REPORT_EDIT && !(PE && companyId !== "0")&& (
            <>
              <IconButton
                id="add"
                onClick={handleOnClickAdd}
                icon={<AddIcon />}
                label='Document'
                small
                disabled={!dataPeriods}
              />
              {canCreateReports && isMenuOpen && (
                <Menu
                  id="add-menu"
                  onClose={handleOnCloseMenu}
                >
                  <MenuItem className={createReportTooltip ? "disabled" : ""} onClick={handleOnClickCreateReport}>
                    <>
                      Create Report
                      {createReportTooltip ? 
                        <Tooltip
                          icon={InfoIcon}
                          alignment='left'>
                            {createReportTooltip}
                        </Tooltip> 
                    : undefined}</>
                  </MenuItem>
                  <MenuItem onClick={handleOnClickUploadDocument}>Upload Document</MenuItem>
                </Menu>
              )}
            </>
          )
        }
      >
        Reports & Certificates
      </Header>
      <main>
        <Table
          isLoading={isLoading}
          quickFilters={[{
            key: 'reportTypeString',
            label: 'Type',
            options: ["Carbon Report", ...documentTypes.filter(item => item !== "")],
          }]}
          stickyHeader
          headers={[
            {
              key: "title",
              label: "Name"
            },
            {
              key: "yearEnds",
              label: "Reporting Period"
            },
            {
              key: "totaltCO2e",
              label: "Total tCO2e",
              type: "number"
            },
            {
              key: "checksStatus",
              label: "Checks"
            },
            {
              key: "fileType",
              label: "File Type"
            },
            {
              key: "more",
              label: ""
            },
          ]}
          data={reports as unknown as { [key: string]: string; }[]}
          rowComponent={(item) => {
            const data = item as CompanyReport | CompanyDocument;
            const key = JSON.stringify(data);
            return (
              <ReportListItem
                key={key}
                data={data}
                onClick={() => REPORT_CREATE && data as CompanyReport && handleReportClick((data as CompanyReport).reportId)}
                onEditClick={handleOnClickEdit}
                onDelete={handleOnClickDelete}
              />
            );
          }}
        />
      </main>
      { isModalOpen && (
        <DocumentModal
          onClose={onClose}
          data={modalDocument}
        />
      )}
    </section>
  );
}

export default ReportsList;
