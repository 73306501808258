import { API_NET_ZERO_PLAN } from "../constants";
import { checkAuthorised } from "../helpers/checkAuthorisedAPI";
import { formatCompanyIdQueryString } from "../helpers/formatCompanyId";

export const getTags = (netZeroPlanId: number, companyId: string | number): Promise<string[]> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetTags?netZeroPlanId=${netZeroPlanId}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      if (res.success) {
        return res.payload;
      } else {
        throw new Error(res.message);
      }
    });
}
