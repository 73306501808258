import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { useCompanyId } from "../../context";
import { getAccountsNominals } from "../../api/DataIntegration";
import { DynamicSearcher, Query, SearcherFactory } from "@m31coding/fuzzy-search";
import { AccountsNominals } from "../../types/DataIntegrations";
import useCompany from "../Company/useCompany";
import { getAllMappableActivities } from "../../api/Activity";
import { Activity } from "../../types/DataPeriod";


type MappingActivity = Activity & {
  nominals?: AccountsNominals;
  suggestedNominals?: AccountsNominals;
};

type MappingActivities = MappingActivity[];

const useAccountMappings = (integrationId?: string) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [pendingSync, setPendingSync] = useState(false);
  const [accountNominals, setAccountNominals] = useState<AccountsNominals>();
  const [activities, setActivities] = useState<MappingActivities>();
  const [searcher, setSearcher] = useState<DynamicSearcher<Activity, number>>();

  const fetchApi = useCallback(async () => {
    if(company?.id && integrationId) {
      setLoading(true);
      await Promise.all([
        getAccountsNominals(parseInt(integrationId), company?.id),
        getAllMappableActivities()
      ])
        .then((res) => {
          mapResults(res?.[0], res?.[1]);
        })
        .catch((err) => {
          if(err.toString().toLowerCase().includes("failed to retrieve financial data")) {
            setPendingSync(true);
          } else {
            addAlert({ id: `Failed To Retreive Accounts Nominals`, type: "error", title: "Failed to load accounts data", content: "Failed to load the accounts nominals data, please refresh to try again." });
          }
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company, integrationId]);
  
  const mapResults = (_nominals: AccountsNominals, _activities: Activity[]) => {
    _activities.sort((a, b) => a.name.localeCompare(b.name));
    
    let _searcher = SearcherFactory.createDefaultSearcher<Activity, number>();
    _searcher.indexEntities(
      _activities ?? [],
      a => a.id,
      a => [a.name, ...(a.searchTerms ?? [])]);
    setSearcher(_searcher);

    setActivities(_activities);

    _nominals.forEach(res => res.predicted = -1);
    _nominals.sort((a, b) => a.accountName.localeCompare(b.accountName));

    setAccountNominals(_nominals.map((_nominal) => {
      let result = _searcher?.getMatches(new Query(_nominal.accountName, 10, 0.1));

      return {
        ..._nominal,
        matches: result?.matches.filter(m => m.quality > 0.2)
      };
    }));
  }

  const updateAccountMappings = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi]);

  useEffect(() => {
    setActivities((prev) => prev?.map((item) => {
      const nominals = accountNominals?.filter((_nominal) => _nominal.activityId === item.id);
      if (nominals) {
        return {
          ...item,
          nominals: nominals as unknown as AccountsNominals
        }
      }
      
      return item;
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNominals]);

  return {
    accountNominals,
    activities,
    searcher,
    updateAccountMappings,
    loading,
    pendingSync,
    setActivities
  };
}

export default useAccountMappings;
