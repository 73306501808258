import './styles.scss';

import React from 'react';
import { Accordion, AccordionContent, AccordionHeader, Address, Card, CheckboxButtonV2, ColorPicker, IconButton, SaveIcon, Skeleton, Tag, TextInput, useAlert } from '@flotilla/component-library';
import { Blocker, useNavigate } from 'react-router-dom';

import Header from '../../../../components/HeaderV2';
import useLocation from '../../../../hooks/Location/useLocation';
import useLocationQuestions from '../../../../hooks/Location/useLocationQuestions';
import Question from '../../../../components/Question';
import { Questions, Submissions } from '../../../../types/Question';
import { locationType } from '../../../../types/Location';
import { DATA_COLLECTION_LOCATION_DETAIL_SUBTITLE } from '../../../../assets/content/Subtitles';
import SaveChangesModal from '../../../../components/Modal/SaveChangesModal';
import { useUserAccess } from '../../../../context';
import { getDateYYYYMMDDString } from '../../../../helpers/dateFormatter';
import ActivityTooltip from '../../Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';

interface LocationDetailProps {
  className?: string;
};

const LocationDetail: React.FC<LocationDetailProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { LOCATION_EDIT } = userAccess;
  
  const {
    location,
    loading: locationLoading,
    updateLocation,
    saveLocation,
    updatedLocation
  } = useLocation();

  const {
    questionGroups,
    updateSubmissions,
    saveSubmissions,
    updatedSubmissions,
    loading: locationQuestionsLoading,
    submissions
  } = useLocationQuestions();

  const handleSaveLocation = () => {
    const mandatoryQuestions = questionGroups?.flatMap(g => g.questions?.flatMap(q => 
      q?.mandatory 
        ? [q, ...q.questionAnswers
            .flatMap(a => a.linkedQuestion && a.linkedQuestion.mandatory &&
              submissions?.some(s => s.questionAnswerId === a.id) ? [a.linkedQuestion] : [])] 
        : []
    )) ?? [];

    if(mandatoryQuestions?.some(q => !submissions?.some(s => s.questionId === q?.id)))
    {
      addAlert({
        id: `Location mandatory fields check ${location?.id}`,
        type: "error",
        title: "Failed to save location details",
        content: "One or more mandatory questions, indicated by an '*', are unanswered. Please answer them and try again."
      });
      return;
    }

    if (LOCATION_EDIT) {
      Promise.all([
        saveSubmissions(),
        saveLocation()
      ])
      .then(() => {
        addAlert({
          id: `Location Saved ${location?.id}`,
          type: "success",
          title: "Location Saved Successfully"
        });
      })
      .catch(() => {
        addAlert({
          id: `Location Saved ${location?.id}`,
          type: "error",
          title: "Location Save Unsuccessful",
          content: "Location failed to save please try again."
        });
      });
    }
  }

  const handleOnClickModalSaveLeave = (navigateBlocker: Blocker) => {
    handleSaveLocation();
    navigateBlocker.proceed?.();
  }

  if (location) {
    const {
      nameConstant: name,
      address,
    } = location;

    const handleOnChange = (questionId: number, updatedSubmissions: Submissions = []) => {
      updateSubmissions(questionId, updatedSubmissions);
    }

    const renderQuestions = (questions?: Questions) => {
      return questions?.map((question) => (
        <>
          <Question
            key={question.id}
            question={question}
            onChange={handleOnChange}
            disabled={!LOCATION_EDIT}
          />
        </>
      )) ?? <></>;
    }

    return (
      <section id="location-detail-page" className={className}>
        <Header
          rightChildren={
            () => LOCATION_EDIT && (
              <IconButton
                id="save-location"
                onClick={handleSaveLocation}
                icon={<SaveIcon />}
                isLoading={locationLoading || locationQuestionsLoading}
                disabled={location.name.trim() === ''}
                small
              />
            )
          }
          subtitle={DATA_COLLECTION_LOCATION_DETAIL_SUBTITLE}
          onBackClick={() => navigate('..')}
          showBackButton
        >
          <section className="header-container">
            <h3>
              {name}
            </h3>
            <ColorPicker
              id="location-color-picker"
              className="location-color-picker"
              value={location.colorHex || '#ffffff'}
              onChange={(event) => updateLocation({ colorHex: event.currentTarget.value })}
              variant='circle'
              disabled={!LOCATION_EDIT}
            />
            <Tag id="location-type" className="tag">
              {location.type}
            </Tag>
          </section>
        </Header>
        <main>
          {location.type !== "Non-physical" ? <section id="address">
            <h4>Address</h4>
            {Object.keys(address).filter((item) => item !== 'countryId').map((key) => <p key={key}>{address[key as keyof Address]}</p>)}
          </section> :  <section id="address">
            <h4>Country</h4>
            <p>{address.country}</p>
          </section>}
          <Card className="section-container">
            <Accordion className="accordion-container" open>
              <AccordionHeader>
                  <h4>Basic details</h4>
              </AccordionHeader>
              <AccordionContent>
                <section id="location-details">
                  <TextInput
                    id="name"
                    label="Name*"
                    aria-label='Location name*'
                    maxLength={50}
                    value={location.name}
                    onChange={(event) => updateLocation({ name: event.currentTarget.value })}
                    required
                    tooltip={<ActivityTooltip tooltip='This is the name of your location e.g. London. This name should be easily identifiable to all team members as it will be consistently used in reporting and the employee survey.'/>}
                    disabled={!LOCATION_EDIT}
                  />
                  <section id="dates">
                    <TextInput
                      id="start-date"
                      className="date"
                      label="Occupied from"
                      aria-label='Occupied from date'
                      value={location.startDate}
                      type='date'
                      onChange={(event) => updateLocation({ startDate: getDateYYYYMMDDString(event.currentTarget.value) })}
                      alertType={location.startDate === '' ? 'error' : undefined}
                      alertMessage={location.startDate === '' ? 'Date needs to be a suitable value.': undefined}
                      disabled={!LOCATION_EDIT}
                      tooltip={<ActivityTooltip tooltip='This is the date from which you occupied the location. This should be inclusive of all dates for which energy was consumed, not only the dates that employees used the space.' helpText='Lease agreement, utility bills, registry details'/>}
                    />
                    <TextInput
                      id="end-date"
                      className="date"
                      label="to"
                      aria-label='End date'
                      value={location.endDate}
                      type='date'
                      onChange={(event) => updateLocation({ endDate: getDateYYYYMMDDString(event.currentTarget.value) })}
                      alertType={location.endDate === '' ? 'error' : undefined}
                      alertMessage={location.endDate === '' ? 'Date needs to be a suitable value.': undefined}
                      disabled={!LOCATION_EDIT}
                    />
                  </section>
                  <section id="location-type">
                    <label>Location type <ActivityTooltip tooltip='Select the type for which the majority of the space is utilised.'/></label>
                    <article id="options">
                    { locationType.filter((item) => item !== "" && (location.type === "Non-physical" ? item === "Non-physical" : item !== "Non-physical")).map((typeOption, index) => 
                        <CheckboxButtonV2
                          className='checkbox'
                          key={index}
                          label={typeOption}
                          checked={typeOption === location.type}
                          onToggle={() => updateLocation({ type: typeOption })}
                          disabled={!LOCATION_EDIT}
                        />
                      )}
                    </article>
                  </section>
                </section>
                {renderQuestions(questionGroups?.find(qg => qg.id === 1)?.questions.filter(q => q.id > 1))}
              </AccordionContent>
            </Accordion>
          </Card>
          { questionGroups?.filter(g => g.id > 1).map(g => (
            <Card className="section-container">
              <Accordion className="accordion-container" open>
                <AccordionHeader>
                    <h4>{g.title}</h4>
                    {g.subtitle && g.subtitle.length ? <h5>{g.subtitle}</h5> : <></>}
                </AccordionHeader>
                <AccordionContent>
                  {renderQuestions(g.questions)}
                </AccordionContent>
            </Accordion>
          </Card>
          )) }
        </main>
        { LOCATION_EDIT && (
          <SaveChangesModal
            condition={updatedLocation || updatedSubmissions}
            onSave={handleOnClickModalSaveLeave}
          />
        )}
      </section>
    );
  }

  return (
    <section id="location-detail-page" className={className}>
      <Header
        isLoading
        showBackButton
        subtitle={' '}
      />
      <main>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
      </main>
    </section>
  );
}

export default LocationDetail;
