import './styles.scss';

import { FC, HTMLAttributes } from 'react';

import { CompanyReport } from '../../types/Report';
import RAGStatus from '../RAGStatus';
import { Accordion, AccordionContent, AccordionHeader } from '@flotilla/component-library';

interface ReportStatusChecksProps extends HTMLAttributes<HTMLElement> {
  data: CompanyReport;
  loading?: boolean;
};

const ReportStatusChecks: FC<ReportStatusChecksProps> = ({data, className = '', loading = false, ...props}) => {
  const {
    reportValidation
  } = data;

  return (
    <article {...props} className={`report-status-check ${className}`}>
      <Accordion onClick={e => e.stopPropagation()} id="activity-data" className='check-container'>
        <AccordionHeader className={`check-header ${(reportValidation?.activity.missing.length || 0) > 0 ? '' : 'check-header--no-detail'}`}>
          <RAGStatus status={reportValidation?.activity.status} />
          <section className="text">
            <p>All activity data completed</p>
            <p className="subtitle">{reportValidation?.activity.expectedCount || 0} of {reportValidation?.activity.total || 0} Activities</p>
          </section>
        </AccordionHeader>
        {(reportValidation?.activity.missing.length || 0) > 0 && (
          <AccordionContent>
            {reportValidation?.activity.missing.map((item) => (
              <section className='content'><p>{item.name}</p></section>
            ))}
          </AccordionContent>
        )}
      </Accordion>
      <Accordion onClick={e => e.stopPropagation()} id="activity-data" className='check-container'>
        <AccordionHeader className={`check-header ${(reportValidation?.location.missing.length || 0) > 0 ? '' : 'check-header--no-detail'}`}>
          <RAGStatus status={reportValidation?.location.status} />
          <section className="text">
            <p>All activity locations included</p>
            <p className="subtitle">{reportValidation?.location.expectedCount || 0} of {reportValidation?.location.total || 0} Locations</p>
          </section>
        </AccordionHeader>
        {(reportValidation?.location.missing.length || 0) > 0 && (
          <AccordionContent>
            {reportValidation?.location.missing.map((item) => (
              <section className='content'><p>{item.name}</p></section>
            ))}
          </AccordionContent>
        )}
      </Accordion>
      <Accordion onClick={e => e.stopPropagation()} id="activity-data" className='check-container'>
        <AccordionHeader className={`check-header ${(reportValidation?.surveyLocation.missing.length || 0) > 0 ? '' : 'check-header--no-detail'}`}>
          <RAGStatus status={reportValidation?.surveyLocation.status} />
          <section className="text">
            <p>All survey locations included</p>
            <p className="subtitle">{reportValidation?.surveyLocation.expectedCount || 0} of {reportValidation?.surveyLocation.total || 0} Locations</p>
          </section>
        </AccordionHeader>
        {(reportValidation?.surveyLocation.missing.length || 0) > 0 && (
          <AccordionContent>
            {reportValidation?.surveyLocation.missing.map((item) => (
              <section className='content'><p>{item.name}</p></section>
            ))}
          </AccordionContent>
        )}
      </Accordion>
      <section id="activity-data" className='check-container'>
        <RAGStatus status={reportValidation?.surveyResponse.status} />
        <section className="text">
          <p>Survey response outliers</p>
          <p className="subtitle">{reportValidation?.surveyResponse.scoreOutliers.length || 0} outlier scores compared to your average {reportValidation?.surveyResponse.scoreAverage || 0} tCO2e</p>
        </section>
      </section>
    </article>
  )
};

export default ReportStatusChecks;
