export type QuestionGroup = {
  id: number;
  title: string;
  subtitle?: string;
  questions: Questions;
  orderNumber: number;
}

export type Question = {
  id: number;
  questionAnswers: Answers;
  orderNumber: number;
  questionText: string;
  questionType: string;
  submissions: Submissions;
  tooltip?: string;
  helpText?: string;
  mandatory: boolean;
  [key: string]: any;
}

export type Answer = {
  id: number;
  linkedQuestionId?: number;
  linkedQuestion?: Question;
  questionId?: number;
  questionAnswerSubmissions: Submissions;
  orderNumber: number;
  questionAnswerText: string;
  [key: string]: any;
}

export type Submission = {
  id: number;
  locationId?: number;
  questionAnswerId?: number;
  freeTextAnswer?: string;
  createdAt?: string;
  updatedAt?: string;
  questionId?: number;
  dataPeriodLocationQuestionAnswerId?: number;
  dataPeriodQuestionAnswerId?: number;
}

export type QuestionGroups = QuestionGroup[];
export type Questions = Question[];
export type Answers = Answer[];
export type Submissions = Submission[];

export const questionType = [
  "",
  "TextInput",
  "TextArea",
  "Select",
  "Toggle",
  "Checkbox",
  "MultiCheckbox",
  "IntegerInput",
  "DecimalInput"
];
export type QuestionType = typeof questionType[number];

export const getQuestionTypeId = (value: string = "Office"): number => {
  return questionType.findIndex((type) => type === value);
}

export const mapQuestionGroups = (data: Partial<QuestionGroup>[], locationId?: number | string): QuestionGroups => {
  return data.map((g) => ({
    ...g,
    questions: mapQuestions(g.questions ?? [], locationId)
  })) as QuestionGroups;
}

export const mapQuestions = (data: Partial<Question>[], locationId?: number | string): Questions => {
  return (data.map((item: Partial<Question>) => ({
    ...item,
    questionType: questionType[item.questionType as unknown as number],
    ...(item.questionAnswers ? { questionAnswers: mapAnswers(item.questionAnswers, locationId) } : {}),
    ...(item.questionAnswers ? { questionAnswers: mapAnswers(item.questionAnswers, locationId) } : {})
  })) as Questions).sort((a, b) => a.orderNumber - b.orderNumber);
}

export const mapAnswers = (data: Partial<Answer>[], locationId?: number | string): Answers => {
  return data.map((item: Partial<Answer>) => ({
    ...item,
    linkedQuestionId: item.linkedQuestionId,
    questionAnswerSubmissions: mapSubmissions(item.questionAnswerSubmissions ?? [], locationId),
    ...(item.linkedQuestion ? { linkedQuestion: mapQuestions([item.linkedQuestion], locationId)[0] } : {}),
    ...(item.linkedQuestion ? { linkedQuestion: mapQuestions([item.linkedQuestion], locationId)[0] } : {})
  })) as Answers;
}

export const mapSubmissions = (data: Partial<Submission>[], locationId?: number | string): Submissions => {
  return (locationId ? data.filter((item) => item.locationId === locationId) : data).map((item: Partial<Answer>) => ({
    ...item,
    questionAnswerId: item.questionAnswerId
  })) as Submissions;
}
