import { API_COMPANY } from "../../constants";
import { checkAuthorised } from "../../helpers/checkAuthorisedAPI";
import { formatCompanyIdQueryString } from "../../helpers/formatCompanyId";
import { Users, mapUsers } from "../../types/User";

export const getUsers = (id: string): Promise<Users> => {
  return fetch(`${API_COMPANY}/getUsers?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => mapUsers(res.payload));
}