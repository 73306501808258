import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Selector, Table, useAlert } from '@flotilla/component-library';

import { ActionGroup, ActionGroups } from '../../../types/ActionGroupStat';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import { getActionsByDepartment, getActionsByLocation } from '../../../api/Statistics';
import { useCompanyId } from '../../../context';
import { numberLocaleFormatter } from '../../../helpers/numberFormatter';

interface ActionsTableProps {
  className?: string;
};

const ActionsTable: React.FC<ActionsTableProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [viewSelected, setViewSelected] = useState('department');
  const [departmentData, setDepartmentData] = useState<ActionGroups>();
  const [locationsData, setLocationsData] = useState<ActionGroups>();

  useEffect(() => {
    setIsLoading(true);
    netZeroPlanId && companyId && getActionsByDepartment(netZeroPlanId, companyId)
      .then((res) => setDepartmentData(res))
      .catch((error) => {
        addAlert({
          id: `Getting actions ${netZeroPlanId}`,
          type: 'error',
          title: 'Error Getting Actions by Department Stat',
          content: 'Something went wrong, please try again by refreshing the page.',
        });
        console.log('Error while getting Actions by Department Stat: ', error);

      })
      .finally(() => setIsLoading(false));
    netZeroPlanId && companyId && getActionsByLocation(netZeroPlanId, companyId)
      .then((res) => setLocationsData(res))
      .catch((error) => {
        addAlert({
          id: `Getting actions ${netZeroPlanId}`,
          type: 'error',
          title: 'Error Getting Actions by Location Stat',
          content: 'Something went wrong, please try again by refreshing the page.',
        });
        console.log('Error while getting Actions by Location Stat: ', error);
      })
      .finally(() => setIsLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId, companyId]);

  const handleClick = (name: string, status: string) => {
    return () => {
      navigate(`./projectplan?department=${name}&status=${status}`);
    }
  }

  if ((departmentData || locationsData) && !isLoading) {
    return (
      <Card id="actions-table-widget" className={className}>
        <header>
          <h4>
            Actions
          </h4>
          <Selector
            id="view-selector"
            onSelect={(option) => setViewSelected(option.key)}
            selected={viewSelected}
            options={[
              { key: 'department', label: 'By Department' },
              { key: 'location', label: 'By Location' },
            ]}
          />
        </header>
        <Table
          id="actions"
          data={(viewSelected === 'department' ? departmentData : locationsData) as unknown as { [key: string]: string; }[]}
          headers={[
            {
              "label": "Name",
              "key": "name",
            },
            {
              "label": "To do",
              "key": "todo",
            },
            {
              "label": "Active",
              "key": "active",
            },
            {
              "label": "Overdue",
              "key": "overdue",
            },
            {
              "label": "Completed",
              "key": "completed",
            },
          ]}
          rowComponent={(item) => {
            const {
              name = "",
              todo,
              active,
              completed,
              overdue
            } = item as unknown as ActionGroup;
            return (
              <tr>
                <td>{name}</td>
                <td onClick={handleClick(name as string, "To do")}>{numberLocaleFormatter(todo)}</td>
                <td onClick={handleClick(name as string, "Active")}>{numberLocaleFormatter(active)}</td>
                <td onClick={handleClick(name as string, "Overdue")}>{numberLocaleFormatter(overdue)}</td>
                <td onClick={handleClick(name as string, "Completed")}>{numberLocaleFormatter(completed)}</td>
              </tr>
            )
          }}
        />
      </Card>
    );
  }

  return (
    <div id="actions-table-widget--loading" />
  )
}

export default ActionsTable;
