import './styles.scss';

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, Wizard } from '@flotilla/component-library';

import getPages from '../../../../assets/content/CreateWizard/NetZeroPlanSetup';
import { createNetZeroPlan } from '../../../../api/netZeroPlan';
import { useCompanyId, useUserAccess } from '../../../../context';
import useHasReportData from '../../../../hooks/Report/useHasReportData';
import FinalSection from '../../../../assets/content/CreateWizard/NetZeroPlanSetup/Sections/Final';

interface NetZeroPlanCreateProps {
  onCreate?: () => void;
  className?: string;
};

const NetZeroPlanCreate: FC<NetZeroPlanCreateProps> = ({
  className = "",
  onCreate = () => {},
}) => {
  const { userAccess } = useUserAccess();
  const { CARBON_REDUCTION_PLAN_PRODUCT_ACCESS } = userAccess;
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const [hasReportData] = useHasReportData();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<{ [key: string]: string | number }>();
  const interimDate = 2030;

  useEffect(() => {
    !hasReportData && setValues({
      ...values,
      scope1: 50,
      scope2: 50,
      scope3: 50
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReportData]);

  const handleOnComplete = async () => {
    if (values?.['completionDate']) {
      setLoading(true);
      await createNetZeroPlan({
        id: companyId as unknown as number,
        targetDate: values['completionDate'] as string,
        interimTargetYear: interimDate,
        scope1InterimReductionTarget: (values['scope1'] as number) / 100,
        scope2InterimReductionTarget: (values['scope2'] as number) / 100,
        scope3InterimReductionTarget: (values['scope3'] as number) / 100,
      })
        .then(onCreate)
        .catch((error) => {
          addAlert({
            id: `Creating a Net Zero Plan ${companyId}`,
            type: 'error',
            title: 'Error Creating a Net Zero Plan',
            content: error?.message ? error.message.toString() : 'Something went wrong, please try again.',
          });
          console.log('Error while creating Net Zero Plan: ', error);
        })
        .finally(() => setLoading(false));
    }
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    setValues((prev) => ({
      ...prev,
      ...values,
    }));
  }

  return (
    <Wizard
      className={className}
      pages={getPages(hasReportData, CARBON_REDUCTION_PLAN_PRODUCT_ACCESS)}
      values={values}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Net Zero Plan',
        subtitle: 'Welcome to your new Net Zero Plan, we at Flotilla believe that Zero is Everything and look forward to helping you achieve this!'
      }}
      endPage={{
        name: 'Final',
        title: 'Net Zero Plan',
        subtitle: 'Well done you are ready to create your plan.',
        children: <FinalSection values={values}/>,
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default NetZeroPlanCreate;
