import './styles.scss';

import React from 'react';
import { Accordion, AccordionContent, AccordionHeader, Button } from '@flotilla/component-library';

import useDataPeriodSettings from '../../../../../../hooks/DataPeriod/useDataPeriodSettings';
import { Questions } from '../../../../../../types/Question';
import { Location } from '../../../../../../types/Location';
import QuestionGeneric from '../../../../../../components/QuestionGeneric';

interface SettingsTabContentProps {
  className?: string;
  dataPeriodId?: string;
  onChange?: () => void;
  disabled?: boolean;
};

const SettingsTabContent: React.FC<SettingsTabContentProps> = ({
  className,
  dataPeriodId,
  onChange = () => {},
  disabled = false
}) => {
  const {
    questions,
    saveSubmissions,
    updateSubmissions,
    updateLocationSubmissions,
  } = useDataPeriodSettings(dataPeriodId);

  const handleOnClickSave = async () => {
    await saveSubmissions()
      .then(onChange);
  }

  const renderQuestions = (questions?: Questions) => {
    return questions?.map((question) => (
      <>
        <QuestionGeneric
          key={question.id}
          question={question}
          onChange={updateSubmissions}
          disabled={disabled}
        />
      </>
    )) ?? <></>;
  }

  const renderLocationQuestions = (location: Location, questions?: Questions) => {
    return questions?.map((question) => (
      <>
        <QuestionGeneric
          key={question.id}
          question={question}
          onChange={(questionId, submissions) => updateLocationSubmissions(location.id, questionId, submissions)}
          disabled={disabled}
        />
      </>
    )) ?? <></>;
  }

  return (
    <section id="settings-tab-content" className={className}>
      <header>
        <h3>Data Period Settings</h3>
        { !disabled && 
          <Button onClick={handleOnClickSave} disabled={disabled}>Save</Button>
        }
      </header>
      <Accordion className="accordion-container" open>
        <AccordionHeader id="accordion-header">
            <h4>General Settings</h4>
        </AccordionHeader>
        <AccordionContent id="accordion-content">
          {questions?.questions.map((_questions) => (
            <section id="question-group-container">
              <h4>{_questions.title}</h4>
              {_questions.subtitle && _questions.subtitle.length ? <h5>{_questions.subtitle}</h5> : <></>}
              <section  id="questions-container">
                {renderQuestions(_questions?.questions)}
              </section>
            </section>
          ))}
        </AccordionContent>
      </Accordion>
      { questions?.locationQuestions.map(({ location, questions }) => (
        <Accordion
          className={`accordion-container location-accordion ${location.colorHex ? 'location-accordion--color' : ''}`}
          style={location.colorHex ? { borderLeft: `solid 0.5rem ${location.colorHex}` } : {}}
          open
        >
          <AccordionHeader id="accordion-header">
              <h4>{location.name} Settings</h4>
          </AccordionHeader>
          <AccordionContent id="accordion-content">
            {questions?.map((_questions) => (
              <section id="question-group-container">
                <h4>{_questions.title}</h4>
                {_questions.subtitle && _questions.subtitle.length ? <h5>{_questions.subtitle}</h5> : <></>}
                <section  id="questions-container">
                  {renderLocationQuestions(location, _questions?.questions)}
                </section>
              </section>
            ))}
          </AccordionContent>
        </Accordion>
      ))}
    </section>
  );
}

export default SettingsTabContent;
